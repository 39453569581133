import Vue from "vue";
import App from "./App.vue";
import router from "@/router.js";
import VueRouter from "vue-router";

import mixin from "@/mixin.js";
import filter from "@/filter.js";

import Sortable from "sortablejs";
import _ from "lodash";

import "@/scss/index.scss";
import jyyUi from "eztrans-core-ui";
import "eztrans-core-ui/dist/jyyui.css";

import ElementUI from "element-ui";
//import 'ant-design-vue/dist/antd.css';
import "element-ui/lib/theme-chalk/index.css";
console.log(ElementUI, "ElementUI");
import "default-passive-events";

import "./util/error-log"; // error log

import "./assets/iconfont/iconfont.css";
Vue.use(jyyUi);
Vue.use(ElementUI);

import store from "./store";

import VueClipboard from "vue-clipboard2";

Vue.use(VueClipboard);

//Vue.use(VueIconfont)
// Vue.prototype.$bus = new Vue()
// 引入事件总线
// export const bus = new Vue();

Vue.use(mixin);
Vue.use(filter);
Vue.use(VueRouter);

Vue.directive("tableDrop", {
  bind(el, binding) {
    "el-table__header-wrapper";
    /*
          列拖拽
        */
    console.log(el, "这是指令的el");
    const wrapperTr = el.querySelector(".el-table__header-wrapper tr");
    Sortable.create(wrapperTr, {
      animation: 180,
      delay: 0,
      handle: ".cell",
      onEnd: (evt) => {
        // let dropCol = _.cloneDeep(this.dropCol);
        let dropCol = binding.value.dropCol;
        console.log(dropCol, "拖拽之后---", binding.value);
        let { oldIndex, newIndex } = evt;
        const oldItem = _.cloneDeep(dropCol[oldIndex]);
        // const newItem = _.cloneDeep(dropCol[newIndex]);
        // if(newIndex > oldIndex){
        //   newIndex -= 1
        // }
        dropCol.splice(oldIndex, 1);
        dropCol.splice(newIndex, 0, oldItem);
        // console.log(evt,_.cloneDeep(this.dropCol),dropCol,"_.cloneDeep(this.dropCol),dropCol")
        // this.dropCol = _.cloneDeep(dropCol);
        // this.reload();
      },
    });
  },
});
new Vue({
  router,
  store,
  render: (h) => h(App),
  beforeCreate() {
    Vue.prototype.$bus = this;
  },
}).$mount("#app");

export default Vue;
